import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_nyaskosnoren/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {derived_properties: {color: { in: "gula" }}}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				color
				elastic
				form
				waxed
				the_length
			}
			
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "gula-skosnören"
    }}>{`Gula Skosnören`}</h1>
    <p>{`Vad skriker sommar och sol mer än ett par gula skosnören? Uppgradera dina skor genom att köpa ett par gula skosnören lagom till sommaren och vi kan nästan garantera att du kommer bli lite, lite gladare. Det spelar nästan ingen roll vilken färg det är på dina sneakers, ett par gula skosnören kan aldrig vara fel. Däremot kanske du bör tänka till både en och två gånger innan du slänger på ett par gula skosnören på dina finskor.`}</p>
    <CategoriesLinks mdxType="CategoriesLinks"></CategoriesLinks>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      